<template>
  <div>
    <Header />
    <div class="page-title">
      <div class="container">
        <div class="title text-black">Produtos</div>
      </div>
    </div>

    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        v-show="showFilters"
        class="bg-cover"
        @click="showFilters = false"
      ></div>
    </transition>

    <section class="products pt-2-mobile pt-2-tablet">
      <div class="container">
        <div class="subtitle desktop-hidden tablet-hidden">PESQUISE</div>
        <div class="search-holder desktop-hidden tablet-hidden">
          <input
            type="text"
            placeholder="Busca..."
            v-model="objSearch.keyword"
            @keypress.enter="search"
          />
          <font-awesome-icon :icon="['fas', 'search']" @click="search" />
        </div>
        <button
          @click="showFilters = true"
          class="btn border icon filter desktop-hidden tablet-hidden"
        >
          <span>Adicionar filtros</span>
          <font-awesome-icon class="search-icon" :icon="['fas', 'filter']" />
        </button>
        <div v-show="loadingProducts" class="flex-center mt-3">
          <div class="loading2"></div>
        </div>

        <transition enter-active-class="animated fadeIn">
          <div
            v-show="!loadingProducts && products.length < 1"
            class="products-holder mt-2-mobile"
          >
            <p class="text-center text-desc mt-2">Nenhum item encontrado.</p>
          </div>
        </transition>

        <transition enter-active-class="animated fadeIn">
          <div
            v-show="!loadingProducts && products.length > 0"
            class="products-holder mt-2-mobile"
          >
            <router-link
              :to="`produtos/${product.slug}`"
              v-for="product in products"
              :key="product.id"
              class="product"
            >
              <div class="img-holder">
                <img :src="product.image" :alt="product.name" />
              </div>
              <h1>{{ product.name }}</h1>
            </router-link>
          </div>
        </transition>

        <div class="right-bar" :class="{ opened: showFilters }">
          <div class="subtitle mobile-hidden">PESQUISE</div>
          <div class="search-holder mobile-hidden">
            <input
              type="text"
              placeholder="Busca..."
              v-model="objSearch.keyword"
              @keypress.enter="search"
            />
            <font-awesome-icon :icon="['fas', 'search']" @click="search" />
          </div>

          <div class="subtitle mt-2">FILTRE POR CLASSE</div>
          <ul class="categories">
            <li
              @click="selectClass(cat)"
              v-for="cat in categories"
              :key="cat.id"
              :class="{ active: cat.id == objSearch.categoryId }"
            >
              {{ cat.name }}
            </li>
          </ul>

          <div class="subtitle mt-2">FILTRE POR CULTURA</div>
          <ul class="cultures">
            <li
              @click="selectCulture(culture)"
              v-for="(culture, index) in cultures"
              :key="index"
              :class="{ active: culture.isSelected }"
            >
              {{ culture.name }}
            </li>
          </ul>

          <div class="subtitle mt-2">FILTRE POR NUTRIENTES</div>
          <ul class="nutrients">
            <li
              @click="selectNutrient(nutrient)"
              v-for="(nutrient, index) in nutrients"
              :key="index"
              :class="{ active: nutrient.isSelected }"
            >
              {{ nutrient.name }}
            </li>
          </ul>

          <button
            @click="showFilters = false"
            class="btn border icon filter desktop-hidden tablet-hidden"
          >
            <span>Aplicar e fechar</span>
          </button>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
<script>
import http from "@/http";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "Produtos",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      products: [],
      categories: [],
      cultures: [],
      selectedCultures: [],
      nutrients: [],
      selectedNutrients: [],
      showFilters: false,
      loadingProducts: false,
      loadingCultures: false,
      loadingNutrients: false,
      loadingCategories: false,
      objSearch: {
        orderBy: "name-",
        categoryId: "",
        listNutrientId: "",
        listCultureId: "",
        keyword: "",
      },
    };
  },
  created() {
    this.objSearch = { ...this.objSearch, ...this.$route.query };

    this.getCategories();
    this.getCultures();
    this.getNutrients();
    this.getProducts();
  },
  methods: {
    getProducts() {
      this.loadingProducts = true;
      this.products = [];
      let nutrientsSelected = this.selectedNutrients
        .map((n) => n.id)
        .toString();
      let culturesSelected = this.selectedCultures.map((n) => n.id).toString();

      this.objSearch.listNutrientId = nutrientsSelected;
      this.objSearch.listCultureId = culturesSelected;

      this.$router.push({ query: this.objSearch });

      http
        .get(`Product/List?`, {
          params: { ...this.objSearch, ...{ take: "-1" } },
        })
        .then((res) => {
          this.products = res.data.list;
          this.filterCultures();
          this.filterNutrients();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loadingProducts = false;
        });
    },
    scrollTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    search() {
      this.scrollTop();
      this.getProducts();
      this.$forceUpdate();
    },
    selectNutrient(nut) {
      if (this.selectedNutrients.find((el) => el.name === nut.name)) {
        this.selectedNutrients = this.selectedNutrients.filter(
          (e) => e.name !== nut.name
        );
        nut.isSelected = false;
      } else {
        nut.isSelected = true;
        this.selectedNutrients.push(nut);
      }
      this.scrollTop();
      this.getProducts();
      this.$forceUpdate();
    },
    selectCulture(cult) {
      if (this.selectedCultures.find((el) => el.name === cult.name)) {
        this.selectedCultures = this.selectedCultures.filter(
          (e) => e.name !== cult.name
        );
        cult.isSelected = false;
      } else {
        cult.isSelected = true;
        this.selectedCultures.push(cult);
      }
      this.scrollTop();
      this.getProducts();
      this.$forceUpdate();
    },
    selectClass(cat) {
      if (this.objSearch.categoryId == cat.id) {
        this.objSearch.categoryId = "";
      } else {
        this.objSearch.categoryId = cat.id;
      }
      this.scrollTop();
      this.getProducts();
      this.$forceUpdate();
    },
    refreshNutrients() {
      this.nutrients.map((n) => {
        if (
          this.selectedNutrients.filter((e) => e.name === n.name).length > 0
        ) {
          n.isSelected = true;
        }
      });
    },
    refreshCultures() {
      this.cultures.map((n) => {
        if (this.selectedCultures.filter((e) => e.name === n.name).length > 0) {
          n.isSelected = true;
        }
      });
    },
    filterCategory() {
      this.categories = this.products.map((p) => p.category);
    },
    filterCultures() {
      let cultures = [];
      this.products.map((p) => {
        p.cultures.map((c) => {
          if (cultures.filter((e) => e.name === c.name).length <= 0) {
            cultures.push(c);
          }
        });
      });
      this.cultures = cultures.sort(function (a, b) {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      });
      this.refreshCultures();
    },
    filterNutrients() {
      let nutrients = [];
      this.products.map((p) => {
        p.nutrients.map((c) => {
          if (nutrients.filter((e) => e.name === c.name).length <= 0) {
            nutrients.push(c);
          }
        });
      });
      this.nutrients = nutrients.sort(function (a, b) {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      });
      this.refreshNutrients();
    },
    getCategories() {
      this.loadingCategories = true;
      http
        .get(`category/List?orderBy=name-`)
        .then((res) => {
          this.categories = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loadingCategories = false;
        });
    },
    getCultures() {
      this.loadingCultures = true;
      http
        .get(`culture/List?orderBy=name-`)
        .then((res) => {
          this.cultures = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loadingCultures = false;
        });
    },
    getNutrients() {
      this.loadingNutrients = true;
      http
        .get(`nutrient/List?orderBy=name-`)
        .then((res) => {
          this.nutrients = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loadingNutrients = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
section.products .container {
  display: grid;
  justify-content: space-between;
  align-items: flex-start;
  grid-template-columns: 0.8fr 0.2fr;
  gap: 0 3rem;
}

.products-holder {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 25px 15px;
  .product {
    position: relative;
    overflow: hidden;
    &:hover {
      h1 {
        color: var(--primary);
      }
      .img-holder img {
        transform: translate3d(-50%, -50%, 0) scale(1.15);
      }
    }
    .img-holder {
      position: relative;
      width: 100%;
      padding-bottom: 100%;
      overflow: hidden;
      img {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        right: 0;
        margin: 0 auto;
        transform: translate3d(-50%, -50%, 0);
        transition: transform 0.7s ease;
        backface-visibility: hidden;
        image-rendering: -moz-crisp-edges; /* Firefox */
        image-rendering: -o-crisp-edges; /* Opera */
        image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
      }
    }
    h1 {
      font-size: 1.4em;
      text-align: center;
      margin-top: 10px;
      transition: color 0.25s ease;
    }
  }
}

.right-bar {
  position: relative;
}

.subtitle {
  font-family: fontBold;
  font-size: 1.2em;
  color: var(--primary);
  letter-spacing: 2px;
}

.search-holder {
  position: relative;
  width: 100%;
  height: 42px;
  border-bottom: 1px solid #666;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  input {
    position: relative;
    height: 100%;
  }
  svg {
    cursor: pointer;
  }
}

.categories {
  position: relative;
  width: 100%;
  margin: 15px auto 0 auto;
  li {
    position: relative;
    font-size: 1.2em;
    margin-top: 15px;
    cursor: pointer;
    &.active {
      color: var(--yellow2);
    }
  }
}

.cultures,
.nutrients {
  position: relative;
  width: 100%;
  margin: 15px auto 0 auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  li {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 10px;
    border: 1px solid #ccc;
    border-radius: 2px;
    cursor: pointer;
    transition: background-color 0.25s ease, border-color 0.25s ease,
      color 0.25s ease;
    &.active {
      border-color: var(--primary);
      background-color: var(--primary);
      color: #fff;
    }
    .remove {
      position: relative;
    }
  }
}

@media screen and(max-width: 720px) {
  section.products .container {
    display: block;
  }
  .products-holder {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 25px 12px;
  }

  .right-bar {
    position: fixed;
    width: 82vw;
    height: 100vh;
    padding: 12px;
    top: 0;
    right: 0;
    overflow-y: auto;
    transform: translateX(84vw);
    background-color: #fff;
    box-shadow: -1px 2px 6px 0 rgba(0, 0, 0, 0.25);
    transition: transform 0.3s ease;
    z-index: 999;
    &.opened {
      transform: translateX(0);
    }
  }
  button.btn.border.icon.filter {
    border-width: 1px;
    width: 100%;
  }
}

@media only screen and (min-width: 721px) and (max-width: 1159px) {
  section.products .container {
    grid-template-columns: 1fr 0.2fr;
    gap: 0 1.5rem;
  }
  .products-holder {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 25px 12px;
  }
}

@media only screen and (min-width: 1160px) {
  .products-holder {
    grid-template-columns: repeat(auto-fit, minmax(200px, 0.3fr));
  }
}
</style>
