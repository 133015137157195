import { render, staticRenderFns } from "./Produtos.vue?vue&type=template&id=e99e69c0&scoped=true&"
import script from "./Produtos.vue?vue&type=script&lang=js&"
export * from "./Produtos.vue?vue&type=script&lang=js&"
import style0 from "./Produtos.vue?vue&type=style&index=0&id=e99e69c0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e99e69c0",
  null
  
)

export default component.exports